import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Layout from '../components/Layout/Layout'
import Hero from '../components/Hero/Hero'

const ns = `visit-page`

const VistPage = ({ data }) => {
  const { markdownRemark } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <Layout>
      <div className={rootClassnames}>
        <Hero
          hero={{
            urlMobile:
              markdownRemark.frontmatter.hero.hero_image_mobile.publicURL,
            urlDesktop:
              markdownRemark.frontmatter.hero.hero_image_desktop.publicURL,
          }}
        />
        <div className={`${ns}__container`}>
          <h1 className="page--title">{markdownRemark.frontmatter.title}</h1>
          <div className={`${ns}__content-container`}>
            {markdownRemark.frontmatter.sections.map((section, index) => (
              <div key={index} className={`${ns}__sections`}>
                <div
                  className="anchor"
                  id={section.title.replace(/\s+/g, '-').toLowerCase()}
                />
                <h2 className="sub-title">{section.title}</h2>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {section.content}
                </ReactMarkdown>
              </div>
            ))}

            {markdownRemark.frontmatter.covid_section.show_section && (
              <div className={`${ns}__visitor-policies ${ns}__sections`}>
                <div className={`${ns}__visitor-policies-container`}>
                  <h2 className="sub-title">
                    {markdownRemark.frontmatter.covid_section.covid_heading}
                  </h2>

                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {markdownRemark.frontmatter.covid_section.covid_content}
                  </ReactMarkdown>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default VistPage

export const visitPageQuery = graphql`
  query VisitPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        hero {
          hero_image_desktop {
            publicURL
          }
          hero_image_mobile {
            publicURL
          }
        }
        sections {
          title
          content
        }
        covid_section {
          show_section
          covid_content
          covid_heading
        }
      }
    }
  }
`
